import Check from '../pages/Check'
import Home from '../pages/Home'
import Profile from '../pages/Profile'
import Report from '../pages/Report'

const routes = [
	{
		path: '/',
		title: '首页',
		component: Home,
		exact: true
	},
	{
		path: '/report',
		title: '申报',
		component: Report,
		exact: true
	},
	{
		path: '/check',
		title: '审核',
		component: Check,
		exact: true
	},
	{
		// path: '/profile/:id',
		path: '/profile',
		title: '我的',
		component: Profile,
		exact: true
	}
]

export default routes
