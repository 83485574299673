import { Container } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import Axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import OrderLists from '../../../components/Lists/OrderLists'

const useStyles = makeStyles(theme => ({
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    container: {
        padding: theme.spacing(2)
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

export default function Reported(props) {
    const classes = useStyles()
    const { toggleReported, handleClose = () => {} } = props
    const [orders, setOrders] = useState([])
    const { id } = props
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        const fetchData = async () => {
            let res = await Axios.get(`${process.env.REACT_APP_API_URL}/groups`)
            console.log('123')
            if (res.status === 200) {
                const groupOne = res.data.find(item => {
                    return item.property === '已通'
                })
                const groupTwo = res.data.find(item => {
                    return item.property === '已驳'
                })

                res = await Axios.get(`${process.env.REACT_APP_API_URL}/users/${id}/orders`)
                if (res.status === 200) {
                    const loadOrders = res.data.filter(item => {
                        return item.group._id === groupOne._id || item.group._id === groupTwo._id
                    })
                    setOrders(loadOrders)
                    return
                }
            }
            enqueueSnackbar('获取失败，请检查网络并刷新重试!', { variant: 'error' })
        }

        fetchData()
    }, [])

    return (
        <Dialog fullScreen open={toggleReported} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar position='sticky'>
                <Toolbar>
                    <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='Close'>
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <Typography variant='h6' className={classes.title}>
                        申报记录
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container className={classes.container}>{toggleReported && <OrderLists orders={orders} />}</Container>
        </Dialog>
    )
}
