import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import React, { createContext, useEffect, useState } from 'react'
import ProfileDetails from '../../components/Lists/ProfileLists'
import { main_config } from '../Common/MainLayout'
import Reported from './Sections/Reported'

export const toggleState = createContext(null)

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1, 0)
    },
    list: {
        width: 250
    },
    container: {
        padding: theme.spacing(2)
    }
}))

export default function Profile(props) {
    const { setTitle, user_id, setDisShadow } = React.useContext(main_config)
    const classes = useStyles()
    const [toggleReported, setToggleReported] = useState(false)

    useEffect(() => {
        setTitle('我的信息')
        setDisShadow(true)
        return () => {
            setDisShadow(false)
        }
    }, [setTitle, setDisShadow])

    const handleClose = () => {
        setToggleReported(false)
    }

    return (
        <Container className={classes.container}>
            <ProfileDetails user={user_id} />
            <Button variant='contained' color='primary' className={classes.button} fullWidth>
                修改信息
            </Button>
            <Button
                variant='contained'
                color='primary'
                className={classes.button}
                fullWidth
                onClick={() => setToggleReported(true)}
            >
                申报记录
            </Button>

            <Reported id={user_id} handleClose={handleClose} toggleReported={toggleReported} />
        </Container>
    )
}
