import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'

import Typography from '@material-ui/core/Typography'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import React from 'react'
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    title: {
        flexGrow: 1
    }
}))

function ElevationScroll(props) {
    const { children, disShadow } = props
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0
    })

    return React.cloneElement(children, {
        elevation: trigger && disShadow ? 4 : 0
    })
}

function ButtonAppBar(props) {
    const classes = useStyles()

    return (
        <>
            <ElevationScroll {...props}>
                <AppBar>
                    <Toolbar>
                        <Typography variant='h6' className={classes.title}>
                            {props.title}
                        </Typography>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <Toolbar />
        </>
    )
}

export default withRouter(ButtonAppBar)
