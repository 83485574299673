import React from 'react'
import { Dialog, CardMedia, DialogActions, Button, Link, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    dialogArea: {
        width: '100%',
        margin: theme.spacing(1)
    }
}))

export default function LightBoxDialog(props) {
    const claesses = useStyles()
    const { lightBox, closeLightBox, url } = props
    return (
        <Dialog
            open={lightBox}
            onClose={closeLightBox}
            classes={{
                paperScrollPaper: claesses.dialogArea
            }}
        >
            <CardMedia component='img' src={url} alt='Failed to load!' />
            <DialogActions>
                <Button variant='contained' color='primary' component={Link} href={url} rel='noopener' target='_blank'>
                    打印
                </Button>
            </DialogActions>
        </Dialog>
    )
}
