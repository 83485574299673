import {
    Aborter,
    AnonymousCredential,
    BlockBlobURL,
    ContainerURL,
    ServiceURL,
    StorageURL,
    uploadBrowserDataToBlockBlob
} from '@azure/storage-blob'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import Axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import ReportDetails from '../../../components/Forms/ReportDetails'
import OrderListDetails from '../../../components/Lists/OrderListDetails'
import { main_config } from '../../Common/MainLayout'

const useStyles = makeStyles(theme => ({
    input: {
        display: 'none'
    },
    button: {
        margin: theme.spacing(1, 0)
    },
    root: {
        display: 'flex'
    },
    title: {
        fontSize: 16
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700]
        }
    },
    textField: {
        marginBottom: theme.spacing(2)
    },
    media: {
        height: 230
    },
    ListFlex: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    actionArea: {
        marginTop: theme.spacing(1)
    }
}))

export default function Tab0(props) {
    const classes = useStyles()
    const { profile } = React.useContext(main_config)
    const [openDialog, setOpenDialog] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const [token, setToken] = useState('')

    const [order, setOrder] = useState({
        invoiceInfo: {
            amount: 0,
            date: '',
            urls: [],
            body: '',
            type: '电子发票'
        },
        group: '',
        createdBy: ''
    })

    const { invoiceInfo } = order

    useEffect(() => {
        const fetchData = async () => {
            let res = await Axios.get(`${process.env.REACT_APP_API_URL}/groups`)
            if (res.status === 200) {
                const loadGroup = res.data.find(item => {
                    return item.property === '保存'
                })
                setOrder({ ...order, group: loadGroup._id })
            } else {
                enqueueSnackbar('加载失败，请刷新重试！', { variant: 'error' })
            }
        }

        fetchData()
    }, [profile])

    const timer = React.useRef()

    const [loading, setLoading] = React.useState(false)

    const handleChange = name => event => {
        setOrder({
            ...order,
            invoiceInfo: {
                ...invoiceInfo,
                [name]: event.target.value
            }
        })
    }

    const handleUpload = async e => {
        if (e.target.files.length === 0) {
            return
        }
        setLoading(true)

        const image = e.target.files[0] || e.dataTransfer.files[0]

        const result = await Axios.get(`${process.env.REACT_APP_API_URL}/assets/accessToken`)
        const loadToken = result.data.sasToken
        setToken(loadToken)

        const anonymousCredential = new AnonymousCredential()
        const pipeline = StorageURL.newPipeline(anonymousCredential)

        const _url = encodeURI(`${process.env.REACT_APP_API_IMAGE_URL}`)
        const serviceURL = new ServiceURL(`${_url}?${loadToken}`, pipeline)
        const containerURL = ContainerURL.fromServiceURL(serviceURL, 'images')
        const blobURL = BlockBlobURL.fromContainerURL(containerURL, image.name)
        const res = await uploadBrowserDataToBlockBlob(Aborter.none, image, blobURL)

        if (res._response.status === 201) {
            const newUrl = res._response.request.url.split('?')[0]
            setOrder({
                ...order,
                invoiceInfo: {
                    ...invoiceInfo,
                    urls: [...invoiceInfo.urls, newUrl]
                }
            })
            enqueueSnackbar('图片上传成功！', { variant: 'success' })
        } else {
            enqueueSnackbar('图片上传失败，请重新上传！', { variant: 'error' })
        }

        if (!loading) {
            setLoading(true)
            timer.current = setTimeout(() => {
                setLoading(false)
            }, 1000)
        }
    }

    const handlePost = () => {
        if (
            invoiceInfo.body === '' ||
            invoiceInfo.date === '' ||
            invoiceInfo.amount === 0 ||
            invoiceInfo.urls.length === 0
        ) {
            enqueueSnackbar('请将数据填完整后再申报！', { variant: 'error' })
            return
        }
        setOpenDialog(true)
    }

    const handleSave = async () => {
        if (invoiceInfo.body === '' || invoiceInfo.date === '' || invoiceInfo.amount === 0) {
            enqueueSnackbar('请将数据填完整后再申报！', { variant: 'error' })
            return
        }

        const res = await Axios.post(`${process.env.REACT_APP_API_URL}/orders`, { ...order, createdBy: profile._id })

        if (res.status === 200) {
            enqueueSnackbar('提交成功！', {
                variant: 'success'
            })

            handleCloseDialog()

            return
        }

        enqueueSnackbar('网络错误，请重试！', {
            variant: 'error'
        })
    }

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    return (
        <React.Fragment>
            <ReportDetails
                order={order}
                handleChange={handleChange}
                handleUpload={handleUpload}
                loading={loading}
                token={token}
            />
            <div className={classes.actionArea}>
                <Button
                    variant='contained'
                    color='primary'
                    className={classes.button}
                    onClick={handlePost}
                    fullWidth
                    disabled={profile.roles[0] === 'U'}
                >
                    申报
                </Button>
                <Button
                    variant='contained'
                    color='primary'
                    className={classes.button}
                    onClick={handleSave}
                    fullWidth
                    disabled={profile.roles[0] === 'U'}
                >
                    保存
                </Button>
            </div>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(!openDialog)}
                fullWidth
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>提交详情</DialogTitle>
                <DialogContent aria-describedby='alert-dialog-description'>
                    <OrderListDetails {...{ ...order, createdBy: profile }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color='primary'>
                        取消
                    </Button>
                    <Button onClick={handleSave} color='primary' autoFocus>
                        提交
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
