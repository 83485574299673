import { Grid } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Axios from 'axios'
import React, { useEffect, useContext } from 'react'
import OrderLists from '../../../components/Lists/OrderLists'
import { main_config } from '../../Common/MainLayout'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: '960px',
        padding: theme.spacing(2),
        margin: '0 auto'
    },
    itemLeft: {
        paddingRight: theme.spacing(1)
    },
    itemRight: {
        paddingLeft: theme.spacing(1)
    }
}))

export default function Section0() {
    const theme = useTheme()
    const classes = useStyles()
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const { profile } = useContext(main_config)
    const [orders, setOrders] = React.useState([])
    const { enqueueSnackbar } = useSnackbar()

    const [toGroup, setToGroup] = React.useState({})
    const [backGroup, setBackGroup] = React.useState({})
    const [isFetch, setIsFetch] = React.useState(false)

    useEffect(() => {
        console.log(isFetch)
        const fetchData = async () => {
            let res = await Axios.get(`${process.env.REACT_APP_API_URL}/groups`)
            if (res.status === 200) {
                const loadGroup = res.data.find(item => {
                    return item.property === '待审'
                })
                const toGroup = res.data.find(item => {
                    return item.property === '已通'
                })
                const backGroup = res.data.find(item => {
                    return item.property === '已驳'
                })
                setToGroup(toGroup._id)
                setBackGroup(backGroup._id)

                if (profile.roles[0] === 'M') {
                    res = await Axios.get(`${process.env.REACT_APP_API_URL}/orders`)
                    if (res.status === 200) {
                        const loadOrders = res.data.filter(item => {
                            return item.group._id === loadGroup._id
                        })
                        setOrders(loadOrders)
                        return
                    }
                } else {
                    res = await Axios.get(`${process.env.REACT_APP_API_URL}/users/${profile._id}/orders`)
                    if (res.status === 200) {
                        const loadOrders = res.data.filter(item => {
                            return item.group._id === loadGroup._id && profile._id === item.createdBy._id
                        })
                        setOrders(loadOrders)
                        return
                    }
                }
            }
            enqueueSnackbar('获取失败，请检查网络并刷新重试!', { variant: 'error' })
        }

        fetchData()
    }, [isFetch, profile])

    const handlePass = _id => async () => {
        const res = await Axios.put(`${process.env.REACT_APP_API_URL}/orders/${_id}`, {
            _id,
            group: toGroup,
            operator: profile._id
        })

        if (res.status === 200) {
            enqueueSnackbar('操作成功！', { variant: 'success' })
            // const newArr = orders.map(item => {
            //     if (item._id === order._id) {
            //         item.group = toGroup
            //         return item
            //     }
            //     return item
            // })
            // setOrders(newArr)
            setIsFetch(!isFetch)
            return
        }
        enqueueSnackbar('操作失败，请重试！', { variant: 'error' })
    }

    const handleReject = _id => async () => {
        const res = await Axios.put(`${process.env.REACT_APP_API_URL}/orders/${_id}`, {
            _id,
            group: backGroup,
            operator: profile._id
        })

        if (res.status === 200) {
            enqueueSnackbar('操作成功！', { variant: 'success' })
            // const newArr = orders.map(item => {
            //     if (item._id === order._id) {
            //         item.group = backGroup
            //         return item
            //     }
            //     return item
            // })
            // setOrders(newArr)
            setIsFetch(!isFetch)
            return
        }
        enqueueSnackbar('操作失败，请重试！', { variant: 'error' })
    }

    return (
        <Grid container className={classes.container} justify='center'>
            {matches ? (
                <>
                    <Grid item xs={6} className={classes.itemLeft}>
                        <OrderLists
                            orders={orders.slice(0, Math.ceil(orders.length / 2))}
                            btn={profile.roles[0] === 'M' ? 1 : null}
                            handlePass={handlePass}
                            handleReject={handleReject}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.itemRight}>
                        <OrderLists
                            orders={orders.slice(Math.ceil(orders.length / 2))}
                            btn={profile.roles[0] === 'M' ? 1 : null}
                            handlePass={handlePass}
                            handleReject={handleReject}
                        />
                    </Grid>
                </>
            ) : (
                <Grid item xs={12}>
                    <OrderLists
                        orders={orders}
                        btn={profile.roles[0] === 'M' ? 1 : null}
                        handlePass={handlePass}
                        handleReject={handleReject}
                    />
                </Grid>
            )}
        </Grid>
    )
}
