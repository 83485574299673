import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import { makeStyles } from '@material-ui/core/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import DescriptionIcon from '@material-ui/icons/Description'
import PersonIcon from '@material-ui/icons/Person'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'

const useStyles = makeStyles({
	bottomNav: {
		width: '100%',
		position: 'fixed',
		bottom: 0,
		left: 0
	}
})

function BottomNav(props) {
	const classes = useStyles()
	const [value, setValue] = React.useState(props.history.location.pathname)

	return (
		<BottomNavigation
			onChange={(event, newValue) => {
				setValue(newValue)
			}}
			value={value}
			showLabels
			className={classes.bottomNav}>
			<BottomNavigationAction
				label="申报"
				component={Link}
				to="/report"
				icon={<DescriptionIcon />}
				value="/report"
			/>
			<BottomNavigationAction
				label="审核"
				component={Link}
				to="/check"
				icon={<CheckCircleIcon />}
				value="/check"
			/>
			<BottomNavigationAction
				label="我的"
				component={Link}
				to="/profile"
				value="/profile"
				icon={<PersonIcon />}
			/>
		</BottomNavigation>
	)
}

export default withRouter(BottomNav)
