import React, { useEffect } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import OrderLists from '../../../components/Lists/OrderLists'
import Axios from 'axios'
import { Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { main_config } from '../../Common/MainLayout'

const useStyles = makeStyles(theme => ({
    container: {
        maxWidth: '960px',
        padding: theme.spacing(2),
        margin: '0 auto'
    },
    itemLeft: {
        paddingRight: theme.spacing(1)
    },
    itemRight: {
        paddingLeft: theme.spacing(1)
    }
}))

export default function Section0() {
    const theme = useTheme()
    const classes = useStyles()
    const matches = useMediaQuery(theme.breakpoints.up('md'))
    const { profile } = React.useContext(main_config)
    const { enqueueSnackbar } = useSnackbar()

    const [orders, setOrders] = React.useState([])

    useEffect(() => {
        const fetchData = async () => {
            let res = await Axios.get(`${process.env.REACT_APP_API_URL}/groups`)

            if (res.status === 200) {
                const loadGroup = res.data.find(item => {
                    return item.property === '已通'
                })

                if (profile.roles[0] === 'M') {
                    res = await Axios.get(`${process.env.REACT_APP_API_URL}/orders`)
                    if (res.status === 200) {
                        const loadOrders = res.data.filter(item => {
                            return item.group._id === loadGroup._id
                        })
                        setOrders(loadOrders)
                        return
                    }
                } else {
                    res = await Axios.get(`${process.env.REACT_APP_API_URL}/users/${profile._id}/orders`)
                    if (res.status === 200) {
                        const loadOrders = res.data.filter(item => {
                            return item.group._id === loadGroup._id && profile._id === item.createdBy._id
                        })
                        setOrders(loadOrders)
                        return
                    }
                }
            }

            enqueueSnackbar('获取失败，请检查网络并刷新重试!', { variant: 'error' })
        }

        fetchData()
    }, [profile])

    return (
        <Grid container className={classes.container} justify='center'>
            {matches ? (
                <>
                    <Grid item xs={6} className={classes.itemLeft}>
                        <OrderLists orders={orders.slice(0, Math.ceil(orders.length / 2))} />
                    </Grid>
                    <Grid item xs={6} className={classes.itemRight}>
                        <OrderLists orders={orders.slice(Math.ceil(orders.length / 2))} />
                    </Grid>
                </>
            ) : (
                <Grid item xs={12}>
                    <OrderLists orders={orders} />
                </Grid>
            )}
        </Grid>
    )
}
