import { AppBar, Tab, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { main_config } from '../Common/MainLayout'
import Section0 from './Sections/Tab0'
import Section1 from './Sections/Tab1'
import Section2 from './Sections/Tab2'

const useStyles = makeStyles(theme => ({
    barFixed: {
        top: theme.spacing(7),
        zIndex: 1
    }
}))

export default function Check() {
    const { setTitle } = React.useContext(main_config)
    const classes = useStyles()
    const [index, setIndex] = React.useState(0)

    const handleChange = (event, newValue) => {
        setIndex(newValue)
    }

    useEffect(() => {
        setTitle('发票列表')
    }, [setTitle])

    return (
        <React.Fragment>
            <AppBar position='sticky' className={classes.barFixed} color='primary'>
                <Tabs value={index} onChange={handleChange} indicatorColor='secondary' variant='fullWidth'>
                    <Tab label='待审核' />
                    <Tab label='已通过' />
                    <Tab label='被驳回' />
                </Tabs>
            </AppBar>
            {index === 0 && <Section0 />}
            {index === 1 && <Section1 />}
            {index === 2 && <Section2 />}
        </React.Fragment>
    )
}
