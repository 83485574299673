import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect } from 'react'
import { main_config } from '../Common/MainLayout'

const useStyles = makeStyles({
    titleRoot: {
        marginTop: '50%',
        textAlign: 'center'
    }
})

export default function Home() {
    const classes = useStyles()
    const { setTitle } = React.useContext(main_config)

    useEffect(() => {
        setTitle('发票管理')
    }, [setTitle])

    return (
        <Typography variant='h4' className={classes.titleRoot}>
            FunNet
        </Typography>
    )
}
