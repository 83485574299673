import React from 'react'
import { Drawer } from '@material-ui/core'
import ProfileDetails from '../Lists/ProfileLists'

export default function ProfileDrawer(props) {
    const { showProifle, closeShowProfile, id } = props
    return (
        <Drawer anchor='bottom' open={showProifle} onClose={closeShowProfile}>
            <ProfileDetails user={id} />
        </Drawer>
    )
}
