import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.css'
import MainLayout from './pages/Common/MainLayout'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import lightBlue from '@material-ui/core/colors/lightBlue'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: lightBlue[500],
            contrastText: '#fff'
        }
    },
    overrides: {
        MUIDataTableBodyCell: {
            root: {
                paddingTop: 0,
                paddingBottom: 0
            }
        }
    }
})

function App() {
    return (
        <React.StrictMode>
            <Router>
                <Switch>
                    <ThemeProvider theme={theme}>
                        <Route path='/' component={MainLayout} />
                    </ThemeProvider>
                </Switch>
            </Router>
        </React.StrictMode>
    )
}

export default App
