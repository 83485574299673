import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect } from 'react'
import { main_config } from '../Common/MainLayout'

const useStyles = makeStyles({
    titleRoot: {
        marginTop: '50%',
        textAlign: 'center',
        color: 'red'
    }
})

export default function Home() {
    const classes = useStyles()
    const { setTitle } = React.useContext(main_config)
    useEffect(() => setTitle('Page not found!'), [setTitle])

    return (
        <Typography variant='h4' className={classes.titleRoot}>
            404
        </Typography>
    )
}
