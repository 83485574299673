import { Toolbar, LinearProgress } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import Axios from 'axios'
import { SnackbarProvider } from 'notistack'
import React, { useEffect, useState, useLayoutEffect } from 'react'
import { BrowserRouter as Redirect, Route, Switch } from 'react-router-dom'
import BottomNav from '../../components/Navigation/BottomNav'
import TitleBar from '../../components/Navigation/TitleBar'
import routes from '../../utils/routes'
import NotFound from './NotFound'

export const main_config = React.createContext(null)

export default function MainLayout(props) {
    const [title, setTitle] = React.useState('发票管理')
    const [disShadow, setDisShadow] = React.useState(false)
    const user_id = '5d2896aeb3b0e9180c7127a2'
    const [profile, setProfile] = useState({})

    useLayoutEffect(() => {
        const fetchData = async () => {
            const res = await Axios.get(`${process.env.REACT_APP_API_URL}/users/${user_id}`)
            setProfile(res.data)
        }

        fetchData()
    }, [])

    return (
        <CssBaseline>
            <TitleBar title={title} disShadow={disShadow} />
            <Switch>
                <main_config.Provider value={{ setTitle, user_id, setDisShadow, profile, setProfile }}>
                    <SnackbarProvider
                        maxSnack={3}
                        autoHideDuration='1500'
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        {profile.roles && profile.roles[0] !== 'U' ? (
                            routes.map(route => <Route key={route.title} {...route} />)
                        ) : (
                            <LinearProgress color='secondary' />
                        )}
                        <Redirect from='*' to='/404' />
                        <Route path='/404' exact component={NotFound} />
                    </SnackbarProvider>
                </main_config.Provider>
                )}
            </Switch>

            <Toolbar />
            <BottomNav />
        </CssBaseline>
    )
}
